import {graphql, StaticQuery} from 'gatsby'
import React, {useState} from 'react'
import Layout from '../components/layout'
import {mapEdgesToNodes} from '../lib/helpers'
// import 'typeface-cairo'
import '../fonts/bebas.eot'
import '../fonts/bebas.ttf'
import '../fonts/bebas.woff'
import '../fonts/bebas.woff2'

const query = graphql`
  query SiteQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      _rawContactInfo(resolveReferences: {maxDepth: 5})
    }
    pages: allSanityPage(limit: 12, sort: {fields: [order, title], order: ASC}) {
      edges {
        node {
          id
          title
          name
          slug {
            current
          }
        }
      }
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(true)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        const pageNodes = data && data.pages && mapEdgesToNodes(data.pages)
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            contactInfoRaw={data.site._rawContactInfo}
            pages={pageNodes}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
